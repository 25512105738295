






















































































































































.class-manage {
    box-sizing: border-box;
    height: 100%;
    padding: 30px 30px 0;
}
